import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TrackModal from './TrackModal';

import {
  setTracks,
  addTrack,
  editTrack,
  deleteTrack
} from '../actions/dataActions';
import {
  List,
  ListItem,
  ListItemText, Typography,
  Grid,
  Paper,Chip,  Box,
  Fab,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { color } from 'chart.js/helpers';
import moment from 'moment';

const TrackList = ({ userid , type_tracking }) => {
  const dispatch = useDispatch();
  const tracks = useSelector((state) => state.data.tracks);
  const loading = useSelector((state) => state.data.loading);
  
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editingTrack, setEditingTrack] = React.useState(null);

  useEffect(() => {
    dispatch(setTracks(userid, (error, data) => {
      if (error) {
        console.error('Failed to fetch tracks:', error);
      } else {
        console.log('Fetched tracks:', data);
      }
    }));
  }, [dispatch, userid]);

  const handleOpenModal = (track = null) => {
    // console.log(track)
    // alert(track["rid"])
    setEditingTrack(track);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingTrack(null);
    setIsModalOpen(false);
  };

  const handleSaveTrack = (track, callback) => {
     
    if (track?.id) {
      track.type_tracking = type_tracking;

      dispatch(editTrack(userid, track, (error) => {
        if (error) {
          console.error('Failed to edit track:', error);
        } else {
          handleCloseModal();
          dispatch(setTracks(userid, (err) => {
            if (err) console.error('Failed to fetch updated tracks:', err);
          }));
        }
      }));
    } else {
        track.type_tracking = type_tracking;
        dispatch(addTrack(userid, track, (error, data) => {
          // console.log("SAVE", data)
          if(callback) callback(data)
        if (error) {
          console.error('Failed to add track:', error);
        } else {
          setEditingTrack(data)
          dispatch(setTracks(userid, (err) => {
            if (err) console.error('Failed to fetch updated tracks:', err);
          }));
        }
      }));
    }
  };

  const handleDeleteTrack = (id) => {
    if(window.confirm("Deseja deletar?")){
      console.log("DELETAR",id)
      dispatch(deleteTrack(userid, id, (error) => {
        if (error) {
          console.error('Failed to delete track:', error);
        } else {
          setIsModalOpen(false)
          dispatch(setTracks(userid, (err) => {
            if (err) console.error('Failed to fetch updated tracks:', err);
          }));
        }
      }));
    }
    
  };

  return (
    <div>
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
       {!loading && Array.isArray(tracks) && tracks.length == 0 && (
        <div style={{ textAlign: 'center' , margin:30 }}>
          <Typography variant="body1"  color="textSecondary">
            Você ainda não possui nenhum código de rastreamento cadastrado. Para iniciar gratuitamente, clique no botão abaixo:
             
          </Typography>
        </div>
      )}
      <Grid container spacing={2}>
        { Array.isArray(tracks) &&  tracks && tracks?.map((track) => {
          if(!track || !track["rid"]) return null;
          if(!track.type_tracking) track.type_tracking = "web"
          if(  type_tracking   != track.type_tracking) return null
          return (
            <Grid  item xs={12} sm={6} md={4} key={track["rid"]}>
              <Paper elevation={3} style={{ padding: '1px' }}>
                <List>
                  <ListItem button onClick={() => {
                    handleOpenModal({...track})
                  }}>
                    <ListItemText
                      primary={track.name}
                      secondary={
                        <Box>
                            {track.domains && track.domains.map &&  track.domains.map((domain, index) => (
                              <Chip size='small' style={{marginRight:10}} key={index} label={domain} />
                            ))}
                            <span>{moment(track.created_at).format("DD-MM-YYYY")}</span>
                        </Box>
                      }
                    />
                  </ListItem>
                 
                </List>
              </Paper>

              
            </Grid>
          )
        })}

<Grid  item xs={12} sm={6} md={4}  >
<Paper elevation={3} style={{ padding: '1px', boxShadow:"none" , border:" 1px dashed #ccc", background:"#00ff00", color:"#000", fontWeight:"bold" }}>
              
                  <ListItem button onClick={() => {
                    handleOpenModal({})
                  }}>
                    <ListItemText
                      primary={"NOVO RASTREAMENTO "+type_tracking.toUpperCase()}
                      secondary={
                        <Box style={{color:"#999"}}>
                             Clique aqui para criar um novo código de rastreamento!
                        </Box>
                      }
                    />
                  </ListItem>
                 
                
              </Paper>
              
              </Grid>

      </Grid>
      {/* <Fab
        color="sucess"
        aria-label="add"
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        onClick={() => handleOpenModal()}
      >
        <AddIcon />
      </Fab> */}
      {isModalOpen &&
      <TrackModal
        open={isModalOpen}
        userid={userid}
        type_tracking={type_tracking}
        handleDeleteTrack={handleDeleteTrack}
        onClose={handleCloseModal}
        track={{...editingTrack}}
        onSave={handleSaveTrack}
        isEdit={!!editingTrack}
      />
    }
    </div>
  );
};

export default TrackList;