
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Container, Typography, Card, CardContent, CircularProgress, LinearProgress } from '@mui/material';
import { registerDomain } from '../actions/authActions';  // Adicionar o updateUserDomain
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const RegisterDomain = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLinearLoading, setIsLinearLoading] = useState(false); // Novo estado para Loader Linear
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [domain, setDomain] = useState(user?.domain);
  const [isSubdomain, setIsSubdomain] = useState(true);

  useEffect(() => {
    validateDomain(domain);
  }, [domain]);
  useEffect(() => {
    setDomain(user?.domain);
    if(!user || !user.userid)  navigate('/login');
  }, []);
  console.log(user)
  const validateDomain = (domain) => {
    if(!domain) return false;
    // Regex para subdomínios: deve ter pelo menos um ponto e conter "api."
    const subdomainRegex = /^(?!:\/\/)([^.]+\.)?api\.[^.\s]+$|^(?!:\/\/)([a-zA-Z0-9-_]+\.)+([a-zA-Z0-9-_]+)\.([a-zA-Z]{2,11})$/;
  
    // Verifica se o domínio possui ".com.br" para retorno falso
    const hasComBr = domain.includes(".com.br");
    if(hasComBr) domain = domain.replace(".br","")
    // Aplica a validação do regex
    const isValid = subdomainRegex.test(domain);
   //  && domain.indexOf(".com.br") === -1
    setIsSubdomain(isValid);
    return isValid
  };
  const handleRegister = async () => {
    if (!domain) {
      setErrorMessage('Por favor, insira um domínio válido.');
      return;
    }

    if (!isSubdomain) {
      setErrorMessage('Por favor, insira um subdomínio válido. Ex.: api.google.com');
      return;
    }

    setLoading(true);
    setIsLinearLoading(true);

    await dispatch(registerDomain(domain, (error, userData) => {
      setLoading(false);
      setIsLinearLoading(false);
        console.log(userData, error)
      if (error) {
        setErrorMessage(error);
      } else {
        // Atualizar o domínio do usuário no reducer
        // dispatch(updateUserDomain(domain));
        navigate('/dashboard');
      }
    }));
  };
  function normalizeString(str) {
    if(!str) return ""
    return str.trim()
       .toLowerCase()                    // Converte para minúsculas
      .replace(/\s+/g, '')              // Remove espaços
               // Remove caracteres especiais, mantendo apenas letras e números
  }
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: 500 }}>
          <CardContent>
            <Typography component="h1" variant="h5">
              Registrar um SUB Domínio 
            </Typography>
            {isLinearLoading && <LinearProgress />}
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                InputProps={{ style: { fontSize: 25 } }}          
                fullWidth
                id="domain"
                label="Sub Domínio"
                name="domain"
                multiline
                autoComplete="domain"
                
                autoFocus
                value={domain}
                onChange={(e) => {
                  var value = normalizeString( e.target.value)  
                  setDomain(value);
                  validateDomain(value);
                }}
                error={!isSubdomain}
                helperText={!isSubdomain ? 'Por favor, insira um subdomínio válido. Ex.: api.google.com ( deve ser do mesmo dominio que você deseja fazer o rastreamento)' : ''}
              />
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}
              
              {loading ? (
                <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleRegister}
                  disabled={isSubdomain ==false || loading}
                >
                  Registrar Domínio
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default RegisterDomain;