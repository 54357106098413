
import { API_BASE_URL } from '../api';

export const SET_PIXELS = 'SET_PIXELS';
export const ADD_PIXEL = 'ADD_PIXEL';
export const EDIT_PIXEL = 'EDIT_PIXEL';
export const DELETE_PIXEL = 'DELETE_PIXEL';
export const SHOW_PIXEL_LOADER = 'SHOW_PIXEL_LOADER';
export const HIDE_PIXEL_LOADER = 'HIDE_PIXEL_LOADER';

export const setPixels = (userid, callback) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/pixels`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userid
      }
    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: SET_PIXELS,
        payload: data,
      });
      if (callback) callback(null, data);
    }
  } catch (error) {
    console.error('Set pixels error: ', error);
    if (callback) callback(error, null);
  } finally {
    dispatch(hideLoader());
  }
};

export const addPixel = (userid, pixel, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/pixels`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userid
      },
      body: JSON.stringify(pixel),
    });
    const data = await response.json();
    console.log(data, response.status)
    if(response.status == 500) alert("Erro ao salvar! " + data?.msg) 
    if (data) {
      dispatch({
        type: ADD_PIXEL,
        payload: data.pixel,
      });
      if (callback) callback(null, data.pixel);
    }
  } catch (error) {
    console.error('Add pixel error: ', error);
    if (callback) callback(error, null);
  }
};

export const editPixel = (userid, pixel, callback) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/pixels/edit`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userid
      },
      body: JSON.stringify(pixel),
    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: EDIT_PIXEL,
        payload: data.pixel,
      });
      if (callback) callback(null, data.pixel);
    }
  } catch (error) {
    console.error('Edit pixel error: ', error);
    if (callback) callback(error, null);
  } finally {
    dispatch(hideLoader());
  }
};

export const deletePixel = (userid, id, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/pixels`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userid
      },
      body: JSON.stringify({ id }),
    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: DELETE_PIXEL,
        payload: id,
      });
      if (callback) callback(null, data);
    }
  } catch (error) {
    console.error('Delete pixel error: ', error);
    if (callback) callback(error, null);
  }
};

export const showLoader = () => ({
  type: SHOW_PIXEL_LOADER,
});

export const hideLoader = () => ({
  type: HIDE_PIXEL_LOADER,
});