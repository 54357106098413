import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import { logout } from '../actions/authActions';
import DnsConfigModal from './DnsConfigModal'; // Importando o novo modal
import TeamManagementModal from './TeamManagementModal';
import ProjectListModal from './ProjectListModal'; // Certifique-se de ajustar o caminho conforme necessário

const Header = ({ toggleTheme, isDarkMode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ipv4, setIpv4] = useState("134.209.170.18");
  const [ipv6, setIpv6] = useState("2604:a880:800:10::ebe:b001");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDnsModalOpen, setIsDnsModalOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isProjectListModalOpen, setProjectListModalOpen] = useState(false);
  const email = useSelector((state) => state.auth.email);
  const domain = useSelector((state) => state.auth.domain);
  const master = useSelector((state) => state.auth.master);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  function anonimizarEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      return ""
    }
    const [usuario, dominio] = email.split('@');
    const usuarioAnonimizado = usuario.charAt(0) + '*'.repeat(usuario.length - 1);
    return `${usuarioAnonimizado}@${dominio}`;
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250 }}>
      <List>
        {user?.email && (
          <>
            <ListItem button onClick={() => setIsDnsModalOpen(true)}>
              <ListItemText primary="Configurar Dominio" />
            </ListItem>
            <ListItem>
              <ListItemText primary={anonimizarEmail(user?.email)} />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Sair" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
  console.log("HEAD STORE",localStorage.getItem('email'))
  console.log("HEAD",email, user) 
  console.log("HEAD",domain, master) 

  return (
    <AppBar position="static">
      <Toolbar>

      <ProjectListModal
        open={isProjectListModalOpen}
        onClose={() => setProjectListModalOpen(false)}
      />

      <TeamManagementModal
        open={isTeamModalOpen}
        onClose={() => setIsTeamModalOpen(false)}
        client_rid={user?.client_rid}
      />
        <DnsConfigModal
          open={isDnsModalOpen}
          domain="example.com"
          ipv4={ipv4}
          ipv6={ipv6}
          onClose={() => setIsDnsModalOpen(false)}
        />
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          SellTracking
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box display="flex" alignItems="center">
              {user?.email && master && (
          <Button color="inherit" onClick={() => setIsTeamModalOpen(true)}>
             Equipe
          </Button>
        )}

{user?.email && master && (
                   <Button color="primary" onClick={() => setIsDnsModalOpen(true)} style={{ marginRight: 20 }}>
                   Dominio
                </Button>
              
            )}
         {user?.email   && (
        <Button onClick={() => setProjectListModalOpen(true)}>Projetos</Button>

        )}


         

{user?.email && (
              <>
            
                <Button color="inherit" onClick={handleLogout}>
                  Sair
                </Button>
              </>
            )}

          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;