import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from '../api';

const ProjectListModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [teamProjects, setTeamProjects] = useState([]);
  const userid = useSelector((state) => state.auth.userid);
  const email = useSelector((state) => state.auth.email);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      fetchProjects();
    }
  }, [open]);

  const fetchProjects = async () => {
    setLoading(true);
    const url = `${API_BASE_URL}/auth/team/projects?email=${encodeURIComponent(email)}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "user-id": userid,
        }
      });
      const data = await response.json();
      setTeamProjects(data);
    } catch (error) {
      console.error("Error fetching team projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectClick = (project) => {
    if (window.confirm(`Deseja carregar o projeto "${project.domain}"?`)) {
      // Supondo que você tenha uma action que atualiza o userid do projeto
      dispatch({ type: "UPDATE_PROJECT_USERID", payload: project.rid , domain: project.domain});
      window.location.reload(); // Recarrega a página
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Lista de Projetos</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            {teamProjects && teamProjects.map && teamProjects.map((project) => (
              <ListItem button key={project.id} onClick={() => handleProjectClick(project)}>
                <ListItemText primary={project.domain +" - "+ project.email} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectListModal;