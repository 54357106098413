import { LOGIN, LOGOUT, SET_CODE, USER } from '../actions/authActions';

const initialState = {
  email: localStorage.getItem('email') || null,
  code: null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  userid: localStorage.getItem('userid') || null,
  domain: localStorage.getItem('domain') || null,
  master: hasMater()
};
function hasMater(){
  var user = JSON.parse(localStorage.getItem('user'))
  var domain = localStorage.getItem('domain');
  if(!user || !domain || !user.domain) return false;
  if(user?.domain == domain) return true
  return false;
}
const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case USER:
      localStorage.setItem('user', JSON.stringify(action.payload));
      localStorage.setItem('domain', action.payload.domain);

      // Update timestamp on every user action
      localStorage.setItem('lastLoginTime', new Date().toISOString());
      return { ...state, user: action.payload , domain: action.payload.domain, master: hasMater()};

      case 'UPDATE_PROJECT_USERID':
        localStorage.setItem('userid', action.payload);
        localStorage.setItem('domain', action.domain);
        localStorage.setItem('lastLoginTime', new Date().toISOString());
        return { ...state,  userid : action.payload , domain: action.domain, master: hasMater() }; //user: action.payload ,

        

    case LOGIN:
      localStorage.setItem('email', action.payload.email);
      localStorage.setItem('userid', action.payload.userid);
      localStorage.setItem('lastLoginTime', new Date().toISOString());

      return { ...state, email: action.payload.email, userid: action.payload.userid , domain:action.payload.domain , master: hasMater() };

    case LOGOUT:
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('userid');
      localStorage.removeItem('lastLoginTime');
      return { ...state, email: null, code: null, user: null, userid: null };

    case SET_CODE:
      return { ...state, code: action.payload };

    default:
      return state;
  }
};

export default authReducer;