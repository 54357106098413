
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux'; // Importando o hook useSelector para obter dados do Redux
import { API_BASE_URL } from '../api';

const TeamManagementModal = ({ open, onClose }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const userid = useSelector((state) => state.auth.userid);

  useEffect(() => {
    if (open && userid) {
      fetchTeamMembers();
    }
    console.log(userid)
  }, [open, userid]);

  async function fetchTeamMembers () {
    setLoading(true);
    var url = `${API_BASE_URL}/auth/team/list?client_rid=${userid}`
    console.log("iner",userid,url)
    try {
      var response =  await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'user-id': userid
        },
      }); 
      const data = await response.json();
      setTeamMembers(data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    } finally {
      setLoading(false);
    }
  };

  const addTeamMember = async () => {
    if (!newMemberEmail) return;

    try {
      await fetch(`${API_BASE_URL}/auth/team/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-id': userid
        },
        body: JSON.stringify({ email: newMemberEmail, client_rid: userid }),
      });
      setNewMemberEmail('');
      fetchTeamMembers();
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding team member:', error);
    }
  };

  const removeTeamMember = async (email) => {
    if (!window.confirm(`Deseja remover o membro da equipe com o email: ${email}?`)) return;
    try {
      await fetch(`${API_BASE_URL}/auth/team/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-id': userid
        },
        body: JSON.stringify({ email, client_rid: userid }),
      });
      fetchTeamMembers();
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error removing team member:', error);
    }
  };

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Gerenciar Equipe
          <IconButton onClick={handleOpenAddModal} style={{ float: 'right' }}>
            <AddIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {teamMembers.map((member, index) => (
                <ListItem key={index} button onClick={() => removeTeamMember(member.email)}>
                  <ListItemText primary={member.email} />
                  <IconButton edge="end" onClick={() => removeTeamMember(member.email)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isAddModalOpen} onClose={handleCloseAddModal} fullWidth maxWidth="sm">
        <DialogTitle>Adicionar Novo Membro</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={addTeamMember}
            color="primary"
            startIcon={<AddIcon />}
            disabled={!newMemberEmail}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeamManagementModal;