import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chart from './Chart';
import TrackList from './TrackList';

import PixelList from './PixelList'; // Novo componente
import Footer from './Footer';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { setTracks } from '../actions/dataActions';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch();
  const tracks = useSelector((state) => state.data.tracks);
  const user = useSelector((state) => state.auth.user);
  const userid = useSelector((state) => state.auth.userid);
  const chartData = useSelector((state) => state.data.chartData);
  const email = useSelector((state) => state.auth.email);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userid) return;
    dispatch(setTracks(userid, (error, data) => {
      if (error) {
        console.error('Failed to fetch tracks:', error);
      } else {
        console.log('Fetched tracks:', data);
      }
    }));
  }, [dispatch, userid]);

  if(!user){
    navigate('/');
  }

  return (
    <Container style={{ minHeight: '100vh', padding: '20px 0' }}>
      <Paper style={{ margin: '20px 0', padding: '20px' }}>
        <Chart data={chartData} />
      </Paper>
      <Grid style={{paddingLeft:15, color:"#777"}} item>
                      <Typography variant="h6">Lista de Pixel Configurados</Typography>
      </Grid>
      <Grid style={{ margin: '20px 0', padding: '20px' }}>
        <PixelList userid={userid} /> {/* PixelList acima do TrackList */}
      </Grid>

      <Grid style={{paddingLeft:15, color:"#777"}} item>
           <Typography variant="h6">Rastreamento em Navegação</Typography>
      </Grid>

      <Grid style={{ margin: '20px 0', padding: '20px' }}>
        <TrackList type_tracking="web" userid={userid} />
      </Grid>

      <Grid style={{paddingLeft:15, color:"#777"}} item>
           <Typography variant="h6">Rastreamento por Webhook </Typography>
      </Grid>
      
      <Grid style={{ margin: '20px 0', padding: '20px' }}>
        <TrackList type_tracking="webhook" userid={userid} />
      </Grid>
       
      <Footer />
    </Container>
  );
};

export default Dashboard;