
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PixelModal from './PixelModal';
import {
  setPixels,
  addPixel,
  editPixel,
  deletePixel
} from '../actions/pixelActions';
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  Chip,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

const PixelList = ({ userid }) => {
  const dispatch = useDispatch();
  const pixels = useSelector((state) => state.pixel.pixels);
  const loading = useSelector((state) => state.pixel.loading);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editingPixel, setEditingPixel] = React.useState(null);

  useEffect(() => {
    dispatch(setPixels(userid, (error, data) => {
      if (error) {
        console.error('Failed to fetch pixels:', error);
      } else {
        console.log('Fetched pixels:', data);
      }
    }));
  }, [dispatch, userid]);

  const handleOpenModal = (pixel = null) => {
    setEditingPixel(pixel);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingPixel(null);
    setIsModalOpen(false);
  };

  const handleSavePixel = (pixel, callback) => {
    if (pixel["rid"]) {
      dispatch(editPixel(userid, pixel, (error) => {
        if (error) {
          console.error('Failed to edit pixel:', error);
        } else {
          handleCloseModal();
          dispatch(setPixels(userid, (err) => {
            if (err) console.error('Failed to fetch updated pixels:', err);
          }));
        }
      }));
    } else {
      dispatch(addPixel(userid, pixel, (error, data) => {
        if (callback) callback(data)
        if (error) {
          console.error('Failed to add pixel:', error);
        } else {
          setEditingPixel(null);
          handleCloseModal()
          dispatch(setPixels(userid, (err) => {
            if (err) console.error('Failed to fetch updated pixels:', err);
          }));
        }
      }));
    }
  };

  const handleDeletePixel = (id) => {
    if (window.confirm("Deseja realmente deletar?")) {
      dispatch(deletePixel(userid, id, (error) => {
        if (error) {
          console.error('Failed to delete pixel:', error);
        } else {
            handleCloseModal()
          dispatch(setPixels(userid, (err) => {
            if (err) console.error('Failed to fetch updated pixels:', err);
          }));
        }
      }));
    }
  };

  return (
    <div>
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!loading && Array.isArray(pixels) && pixels.length === 0 && (
        <div style={{ textAlign: 'center', margin: 30 }}>
          <Typography variant="body1" color="textSecondary">
            Você ainda não possui nenhum pixel cadastrado. Para iniciar, clique no botão abaixo.
          </Typography>
        </div>
      )}
      <Grid container spacing={2}>
        {Array.isArray(pixels) && pixels.map((pixel) => (
          <Grid item xs={12} sm={6} md={4} key={pixel.id}>
            <Paper elevation={3} style={{ padding: '1px' }}>
              <List>
                <ListItem button onClick={() => handleOpenModal({...pixel})}>
                  <ListItemText
                    primary={pixel.numero_pixel}
                    secondary={
                      <Box>
                        {pixel.tipo_pixel && (
                          <Chip size='small' style={{marginRight:10}} label={pixel.tipo_pixel} />
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{ padding: '1px', boxShadow: "none", border: "1px dashed #ccc", background: "#00ff00", color: "#000", fontWeight: "bold" }}
          >
            <ListItem button onClick={() => handleOpenModal({})}>
              <ListItemText
                primary={"NOVO PIXEL"}
                secondary={
                  <Box style={{ color: "#999" }}>
                    Clique aqui para criar um novo pixel! Depois crie suas regras de Rastreamento.
                  </Box>
                }
              />
            </ListItem>
          </Paper>
        </Grid>
      </Grid>
      {isModalOpen &&
        <PixelModal
          open={isModalOpen}
          userid={userid}
          onClose={handleCloseModal}
          pixel={{ ...editingPixel }}
          handleDeletePixel={handleDeletePixel}
          onSave={handleSavePixel}
          isEdit={!!editingPixel}
        />
      }
    </div>
  );
};

export default PixelList;